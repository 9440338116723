<template>
<div class="orders">
  <div class="row">
    <div class="col">
      <section class="card">
        <div class="twt-feed purple-darken-bg">
          <div class="corner-ribon black-ribon">
            <i title="Información del estudiante." class="fa fa-info-circle"></i>
          </div>
          <div class="fa fa-user wtt-mark"></div>
          <div class="media">
            <a href="#">
              <upload
                :avatar_id="settings_obj.avatar"
                :avatar_url="settings_obj.avatar_url"
                v-on:get_id_avatar="update_avatar"
                style="width:85px; height:85px;"
                apply_class="align-self-center rounded-circle mr-3"></upload>
            </a>
            <div class="media-body">
              <h2 class="text-white display-6">{{settings_obj.student_name}}</h2>
              <p class="text-light" v-if="settings_obj.phone">{{settings_obj.phone}}</p>
              <p class="text-light" v-else>Sin teléfono</p>
            </div>
          </div>
        </div>
        <div class="weather-category twt-category">
          <ul>
            <li class="active">
              <h5>{{settings_obj.grade_name}}</h5>
              Grado
            </li>
            <li>
              <h5>{{settings_obj.section_name}}</h5>
              Sección
            </li>
            <li>
              <h5 v-if="settings_obj.avg_resumen">{{settings_obj.avg_resumen}}</h5>
              <h5 v-else>0</h5>
              Promedio
            </li>
          </ul>
        </div>
        <footer class="twt-footer">
          <a href="#"><i class="fa fa-map-marker"></i></a>
          <span v-if="settings_obj.address">
          {{settings_obj.address}}</span><span v-else>  Sin dirección</span>
          <span class="pull-right" title="Edad">
            {{settings_obj.age}}
          </span>
        </footer>
      </section>
    </div>
  </div>
</div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'Profile',
  components: {
    upload: () => import('../components/Upload.vue'),
  },
  data() {
    return {      
      data_student: [],
      url: 'registers/'
    };
  },
  methods: {
    update_avatar(avatar_id) {
      let payload = { }
      payload.change_avatar = 'True'
      payload.id = this.settings_obj.student
      payload.avatar = avatar_id
      const self = this;
      this.putRequest(payload).then((data) => {
        self.$store.dispatch('set_avatar_url', { avatarUrl: data.data.avatar_url });
        self.$store.dispatch('set_avatar_id', { avatarId: data.data.avatar_id });
        self.save_storage('settings', this.settings_obj);        
        self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
      }).catch((error) =>{
        console.log(error);
        self.$swal.fire('Error!', 'Ha fallado la actualización.', 'error');
      });
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    async putRequest(payload) {
      const data = await this.$http.put(`students/${payload.id}/`, payload);
      return data;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, { params: payload });
      return result;
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
